<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  FieldValueType,
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
  useFormula,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
} = useField(props, emit)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

// Initialize formula evaluation, after setting calculated value
const { createFormula } = useFormula()
const { evaluated } = createFormula(props.node.formula, props.parentPath)

if (evaluated.value) {
  modelValue.value = evaluated.value
}

watch(evaluated, () => {
  modelValue.value = evaluated.value
  handleChange(evaluated.value, false)
})

const displayValue = computed(() => {
  if (props.node.valueType === FieldValueType.CURRENCY) {
    return Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }).format((modelValue.value as number) || 0)
  }

  return modelValue.value
})

useFieldCache(props.node, parentValue, props.preserveCache)
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <FormKit
      :id="id"
      :disabled="true"
      :type="type"
      :name="node.name"
      :label="label"
      :model-value="displayValue"
      outer-class="formkit-disabled:!opacity-100"
      help-class="!text-gray-550 text-xs"
      inner-class="bg-gray-800 border-gray-800 bg-opacity-100"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
    >
      <!-- help="Calculated" -->
      <template
        v-if="node.prefix && node.valueType !== FieldValueType.CURRENCY"
        #prefix
      >
        <span
          class="bg-gray-750/20 border-gray-750/50 flex h-10 items-center rounded-l-[5px] border-r px-2.5 pt-px text-xs"
        >
          {{ node.prefix }}
        </span>
      </template>
      <template v-if="node.suffix" #suffix>
        <span
          class="bg-gray-750/20 border-gray-750/50 flex h-10 items-center rounded-r-[5px] border-l px-2.5 pt-px text-xs"
        >
          {{ node.suffix }}
        </span>
      </template>
    </FormKit>
  </ManagerSharedFieldBase>
</template>
